import equipe from "../assets/images/equipe.jpg";
import atelierSport from "../assets/images/atelierSport.jpg";
import about from "../assets/images/about.jpg";
import aboutIllu from "../assets/images/about-illu.svg";
import { Link } from "react-router-dom";

function AboutUsPage() {
    return (
      <div>
        <div className="about">
          <div className="about__text-container">
            <h2 className="about__title">
              Qui se cache<br/>
              <strong>
                <i>derrière Parlons Règles ?</i>
              </strong>
            </h2>
            <p className="about__text">
              Parlons Règles est un programme d’éducation menstruelle pensé et conçu par l’association Règles Élémentaires. Depuis 2015, Règles Élémentaires est l’association française de référence sur les règles. Elle est pionnière dans l’émergence du sujet de la précarité menstruelle et ses conséquences désastreuses sur la vie de millions de femmes.
            </p>
            <p className="about__text">
              En 9 ans, nous avons collecté et redistribué 20 000 000 de produits périodiques par le biais de plus de 1000 associations partenaires sur tout le territoire. Mais pour combattre le tabou millénaire des règles, il faut aller plus loin.
            </p>
            <p className="about__text">
              Nous avons commencé à faire entrer les règles dans le débat public grâce à des mobilisations politiques, des événements ou des grandes campagnes de communication et de sensibilisation. Nous continuons à faire exister le sujet au quotidien, lors d'ateliers scolaires, de formations professionnelles ou de workshops.
            </p>
          </div>
          <div className="about__img-container">
            <img className="about__img" src={equipe} alt="19 personnes portant un t-shirt Règles Élémentaires" />
          </div>
        </div>  
        <div class="about-red">
          <p class="about-red__text">
            Notre mission est de permettre à toutes les personnes de vivre leurs règles dans de bonnes conditions et faire qu’avoir ses règles ne soit plus un frein dans leur quotidien.
          </p>
        </div> 
        <div className="about">
          <div className="about__text-container">
            <p className="about__text">
              Aujourd'hui, l'association est en effet sollicitée pour être l'actrice incontournable de l'éducation menstruelle. Chacune de nos interventions dans les établissements scolaires nous a permis de développer une expertise unique, en lien direct avec les questions que se posent les jeunes de 8 à 14 ans.
            </p>
            <p className="about__text">
              Grâce à des partenariats pilotes avec des relais éducatifs (enseignant·es, infirmier·es scolaires, etc), des parents, et des spécialistes de la santé menstruelle (médecin, sage-femmes, etc) nous avons pu développer des contenus uniques, pertinents et adaptés. Ils permettent d’aborder (de manière adaptée) le tabou et son histoire, le processus physiologique, les bons gestes à adopter et nous répondons à toutes les questions des élèves (et elles sont toujours nombreuses !).
            </p>
            <p className="about__text">
              Pour que l'éducation menstruelle ait plus d'impact, il nous faut la penser dans le temps, et par tous les moyens. Les ateliers sont une étape clé dans la transmission d'informations. Des outils complémentaires, clés en main et diffusables facilement constituent une autre étape complémentaire. Ces outils sont aujourd'hui regroupés dans le programme Parlons Règles.
            </p>
            <Link to="https://www.regleselementaires.com/" className="about__button">
              Découvrir l'association
            </Link> 
            <Link to="mailto:contact@regleselementaires.com" className="about__button about__right-button">
              Nous contacter
            </Link> 
          </div>
          <div className="about__img-container">
            <img className="about__img" src={atelierSport} alt="Une intervenante qui parle des règles à un public de jeunes sportives" />
          </div>
          <div className="about__text-container">
            <h2 className="about__title" style={{marginTop: "30px"}}>
              Pourquoi<br/>
              <strong>
                <i>Parlons Règles ?</i>
              </strong>
            </h2>
            <p className="about__text">
            En 2022, près de 60% des personnes interrogées n’ont reçu aucun enseignement formel sur les règles. De fait, dans les programmes officiels, les règles sont abordées en cours de SVT en 5ème ou en 4ème. Or, l’âge moyen des premières règles est estimé à 12,5 ans. De nombreuses personnes ne savent donc pas ce que sont les règles la première fois qu’elles les ont. Les conséquences de cette méconnaissance sont multiples : précarité menstruelle, mauvaise image de soi, sentiment de honte, persévérance des tabous.
            </p>
          </div>  
          <div className="about__cards-container">
            <div className="about__card-row">
              <div className="about__card-container third-width">
                <div className="about__card-content light-card left-card">
                  <p className="about__big-number">72%</p>
                  <p className="about__card-text">des jeunes filles ont leurs premières règles avant 13 ans</p>
                </div>
              </div>
              <div className="about__card-container two-third-width">
                <div className="about__card-content dark-card right-card">
                  <p className="about__card-text">Avoir ses règles à l’école est un facteur de stress</p>
                  <p className="about__big-number">pour 80%</p>
                  <p className="about__card-text">des jeunes filles</p>
                </div>
              </div>
            </div>
            <div className="about__card-row">
              <div className="about__card-container full-width">
                <div className="about__card-content light-card">
                  <p className="about__card-text">À cause de ces absences, elles sont</p>
                  <p className="about__big-number">1/4</p>
                  <p className="about__card-text">à déclarer que ça a freiné leur progression scolaire</p>
                </div>
              </div>
            </div>
            <div className="about__card-row">
              <div className="about__card-container half-width">
                <div className="about__card-content dark-card left-card">
                  <p className="about__big-number">53%</p>
                  <p className="about__card-text">des filles de plus de 15 ans ont déjà manqué l’école à cause de leurs règles</p>
                </div>
              </div>
              <div className="about__card-container half-width">
              <div className="about__card-content light-card right-card">
                  <p className="about__card-text">Parmi celles qui ont déjà manqué l’école,</p>
                  <p className="about__big-number">68%</p>
                  <p className="about__card-text">sont absentes plusieurs fois par an</p>
                </div>
              </div>
            </div>
          </div>
          <div className="about__illu-container">
            <img className="about__illu" src={aboutIllu} aria-hidden="true" alt="" />
          </div>
        </div>    
        <div className="about" style={{backgroundColor: "#ffdce2"}}>
          <div className="about__text-container">
            <p className="about__text">
              Jusqu'à aujourd'hui, il n'existait aucun programme d'éducation menstruelle exhaustif, adapté aux besoins des jeunes, mais aussi à l'écoute des besoins des relais éducatifs et des parents. Nous avons donc décidé de le créer. Parlons Règles, c'est une plateforme d'éducation menstruelle, co-construite avec les publics cibles, et évolutive dans le temps.
            </p>
            <p className="about__text">
              Cette plateforme inédite, gratuite, expertisée et sans publicité donne accès à de l’information vérifiée au sujet des règles.
            </p>
            <p className="about__text">
              Nous proposons des vidéos sur la thématique des règles à destination des jeunes de 8 à 14 ans en priorité ainsi qu’un espace questions / réponses et un chatbot innovant. Il existe aussi une partie relais et parents avec des articles et des contenus téléchargeables.
            </p>
            <Link to="https://doccollectes.blob.core.windows.net/11-octobre/r%C3%A9sum%C3%A9_%C3%A9tude_11_octobre_RE.pdf" className="about__button">
              Voir notre étude
            </Link> 
          </div>
          <div className="about__img-container">
            <img className="about__img" src={about} alt="Une femme utilisant un smartphone" />
          </div>
        </div>  
        <div className="about">
          <div className="about__text-container">
            <h2 className="about__title">
              C'est quoi<br/>
              <strong>
                <i>l'éducation menstruelle ?</i>
              </strong>
            </h2>
            <p className="about__text">
              L’éducation menstruelle est une pédagogie qui diffuse un socle de connaissances et d'informations suffisant pour :
            </p>
            <p className="about__text" style={{color: "#9129ff"}}>
              1. bien vivre ses règles à tout âge et quel que soit son vécu<br/>
              2. prévenir les risques de précarité menstruelle<br/>
              3. prévenir les maladies liées aux règles (endométriose, adénomyose, SOPK)<br/>
              4. mettre fin aux tabous et aux discriminations liés aux règles<br/> 
              5. favoriser l'égalité active
            </p>
          </div>
        </div>
      </div>
    );
  }

export default AboutUsPage;