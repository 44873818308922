import HomeQuestion from "../../components/HomeQuestion";
import SwapArticle from "../../components/SwapArticle";
import RessourcesParent from "../../components/RessourcesParent";
import HeaderParent from "../../components/HeaderParent";
import PartnersFooter from "../../components/PartnersFooter";

function HomeAdults() {

	return (
		<div className="HomeAdults">
			<HeaderParent />
			<SwapArticle locate="Parents" />
			<HomeQuestion />
			{/*<RessourcesParent />*/}
			<PartnersFooter />
		</div>
	);
}

export default HomeAdults;