import useAxios from '../Hook/useAxios';
import React, { useState, useEffect } from 'react';

function PartnersList(partnerList) {
  if (partnerList.partnerList) {
    return (
      <>
        {partnerList.partnerList.map((partner) => (
          <div key={partner.id} className='partners__logo'>
            <a target='_blank' href={partner.attributes.url} rel="noreferrer">
              <img className="partners__img"  width="250px" src={partner.attributes.logo.data.attributes.url} alt={partner.attributes.name} />
            </a>
          </div>
        ))}
      </>
    );
  } else {
    return <p>Aucun partenaire trouvé</p>;
  }
}

function PartnersPage() {
  const [partnersData, setPartnersData] = useState('');
  const response = useAxios('/partners?populate=*');

  useEffect(() => {
    const getData = () => {
      if (response) {
        const partnerList = response.data.data;
        setPartnersData(partnerList);
      }
    };
    getData();
  }, [response]);

  return (
    <div className="partners">
      <div className='partners__header'>
        <h1 className='partners__title'>Nos <strong>Partenaires</strong></h1>
          <p className='partners__description'>Merci à nos généreux partenaires qui soutiennent Parlons Règles. Sans eux, ce projet ne serait pas possible !</p>
      </div>
      <div className="partners__grid-logo">
        <PartnersList partnerList={partnersData} />
      </div>
      <div className="SocialMedia"></div>
    </div>
  );
}

export default PartnersPage;
