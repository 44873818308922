import React, { useState } from "react";
import Modal from "react-modal";
import useAxios from "../Hook/useAxios";
import axios from "axios";
import logoNotation from "../assets/images/notation_logo.svg";
import sadEmoji from "../assets/images/sad-emoji.png";
import fireEmoji from "../assets/images/fire-emoji.png";
import { MdOutlineStar } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import useMobile from "../Hook/UseMobile";

function RatingModal() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [userRating, setUserRating] = useState(0);
	const [isVoting, setIsVoting] = useState(false);
	const allReview = useAxios("/reviews?populate=*");
    const isMobile = useMobile();

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
        const axiosConfig = {
			headers: {
				Authorization: `Bearer fadf5567ec16976a942a54abf71ab925fcef1080c66feb4db64ef7b5c06d582b02408ca81c159b41ff062da6c576b3198605f24d81ebc572f851c7e8d25d32b6eb8f7045898c468b1db7fb204592e9f1bbcd0bf600d3efa21f1f0331ac45158bd3901b3840efb1fb8f2ff6e0a47467259eb7edb73ee07dccf3e6cab0989a8461`,
			},
		};
		setModalIsOpen(false);
		if (isVoting) {
            const url = `https://admin.parlonsregles.fr/api/reviews/${userRating}`
            const data = {"data" : {
                "rating" : userRating,
                "count" : allReview.data.data[userRating - 1].attributes.count + 1
            }}
			axios
				.put(url, data, axiosConfig)
				.then((response) => {
					// console.log("Réponse de l'API:", response.data);
				})
				.catch((error) => {
					console.error("Erreur lors de la requête PUT:", error);
				});
		}
	};

	const stars = [1, 2, 3, 4, 5];

	const starClic = (rate) => {
		setIsVoting(true);
		setUserRating(rate);
	};

	const modalStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: "fit-content",
			height: "fit-content",
			position: "absolute",
			top: "50%",
			left: "50%",
			right: "0",
			bottom: "0",
			transform: "translate(-50%, -50%)",
			background: "#ffffff",
			padding: "75px",
            border: "2px solid black",
			borderRadius: window.innerHeight <= 750 ? "none" : "20px",
			zIndex: 999,
		},
	};

    const modalStyleMobile = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: "75%",
			height: "75%",
            maxWidth: "800px",
			position: "absolute",
			top: "15%",
			left: "15%",
			right: "0",
			bottom: "",
			background: "#ffffff",
			padding: "25px",
            border: "2px solid black",
			borderRadius: window.innerHeight <= 750 ? "none" : "20px",
			zIndex: 999,
		},
	};

	return (
		<div className="notation">
			<button onClick={openModal} className="notation__button">
				Je donne mon avis
			</button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Laisser un avis"
				shouldCloseOnOverlayClick={true}
				ariaHideApp={false}
				style={isMobile ? modalStyleMobile : modalStyle}
			>
				<div className="notation__modal-container">
                <IoCloseOutline
						className="notation__close-button"
						onClick={closeModal}
					/>
                    <img src={logoNotation} alt="As-tu appris quelques chose sur les règles ?" />
					<div className="notation__star-container">
                        {stars.map((star, index) => {
                            return (
                                <MdOutlineStar
                                    key={star}
                                    className={`notation__star ${
                                        userRating >= index + 1
                                            ? "notation__star--active"
                                            : "notation__star--inactif"
                                    }`}
                                    onClick={() => starClic(star)}
                                />
                            );
                        })}
                    </div>
                    <div className="notation__legend-container">
                        <div>
                            <p>Je n'ai rien<br/> appris <img src={sadEmoji} alt="emoji triste" /></p>
                        </div>
                        <div>
                            <p>J'ai tout<br/> compris ! <img src={fireEmoji} alt="emoji flamme" /></p>
                        </div>
                    </div>
				</div>
			</Modal>
		</div>
	);
}

export default RatingModal;
