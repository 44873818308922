import React from "react";
import Partners from "../../components/PartnersFooter";
import HomeQuestion from "../../components/HomeQuestion";
import TiktokHome from "../../components/TiktokHome";
import SwapVideo from "../../components/SwapVideo";
import SendQuestionContainer from "../../components/SendQuestionContainer";
import YoungHeader from "../../components/YoungHeader";
import ChatbotPopup from "../../components/ChatbotPopup";

function HomeYoung() {

	return (
		<div className="homeYoung">
			<YoungHeader />
			<SwapVideo />
      		<TiktokHome />
			<HomeQuestion />
			<div className="homeYoung__send-question-container">
				<SendQuestionContainer />
			</div>
			<Partners />
			<ChatbotPopup />
		</div>
	);
}

export default HomeYoung;
