import React from "react";
import logoMobile from "../assets/images/parent-head-logo.svg";
import logo from "../assets/images/bulle_header_parents.svg";
import { useNavigate } from "react-router-dom";
import { useUser } from '../Hook/useUser';


function HeaderParent() {
	const navigate = useNavigate();
	const { user } = useUser();

	const goToAllArticleWithTag = (value) => {
		if (user === "parent") {
			navigate("/Parents/AllArticles", { state: { tag: value} });
		} else {
			navigate("/Relais/Blog", { state: { tag: value} });
		}		
	};

	return (
		<div className="headerParent">
			<div className="headerParent__left-container">
                <img className="headerParent__desktop-img" src={logo} alt="Enfin un site pour savoir de quoi on parle" />
                <p className="headerParent__left-container-text">
					Pas facile d’aborder le sujet des règles avec ses enfants… Quel est le bon moment ? À quel âge je peux commencer à en parler ? Avec quels mots ? Cette plateforme est conçue pour donner de l’information aux plus jeunes mais aussi pour accompagner les parents qui peuvent se poser autant de questions que leurs enfants !
                </p>
			</div>
			<div className="headerParent__left-container-mobile">
				<div className="headerParent__logo-container">
					<img src={logoMobile} alt="" aria-hidden="true" />
				</div>
			</div>
			<div className="headerParent__video-container">
				<iframe
					className="headerParent__iframe"
					width="100%"
					height=""
					src="https://www.youtube.com/embed/XwIC5C0WVJg?si=QTkgxVuhjpCToXii"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				></iframe>
				<div className="headerParent__sub-video-container">
					<div className="headerParent__tag-container">
						<button className="headerParent__tag focused" onClick={() => goToAllArticleWithTag("Tabou")}>#Tabou</button>
					</div>
					<p className="headerParent__video-title">Parler des règles en famille</p>
				</div>
			</div>
		</div>
	);
}

export default HeaderParent;
