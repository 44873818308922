import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "../Hook/useUser.jsx";
import logo from "../assets/images/logo-parlons-regles.svg";
import { FaYoutube, FaInstagram, FaTiktok } from "react-icons/fa";

function NavbarFooter() {
	const { user, setModalQuestionIsOpen } = useUser();

	function getNav() {
		switch (user) {
			case "parent":
				return (
					// Liens pour l'utilisateur "Parents"
					<nav className="footer__navbar">
						<Link
							className="footer__navbar-link focused"
							to="/Parents/Articles"
						>
							Le blog des règles
						</Link>
						<Link
							className="footer__navbar-link focused"
							to="/Parents/QuestionsUtilisateurs"
						>
							Questions les plus courantes
						</Link>
						<Link
							className="footer__navbar-link focused"
							to="/AboutUs"
						>
							Qui sommes-nous ?
						</Link>
					</nav>
				);
			case "relai":
				return (
					// Liens pour l'utilisateur "Relai"
					<nav className="footer__navbar">
						<Link
							className="footer__navbar-link focused"
							to="/Relais/Ressources"
						>
							Ressources
						</Link>
						<Link
							className="footer__navbar-link focused"
							to="https://www.regleselementaires.com/agir/ecole/#sensibiliser"
							target="_blank" rel="noreferrer"
						>
							Organiser un atelier
						</Link>
						<Link
							className="footer__navbar-link focused"
							to="/AboutUs"
						>
							Qui sommes-nous ?
						</Link>
					</nav>
				);
			default:
				return (
					// Liens pour l'utilisateur "Jeunes"
					<nav className="footer__navbar">
						<Link
							className="footer__navbar-link focused"
							to="/Jeunes/ToutSurLesRegles"
						>
							Vidéos sur les règles
						</Link>
						<Link
							className="footer__navbar-link focused"
							to="/Jeunes/QuestionsUtilisateurs"
						>
							Questions les plus posées
						</Link>
						{/* Link for keyboard navigation */}
						<a
							href="#"
							className="footer__navbar-link focused"
							onClick={() =>
								setModalQuestionIsOpen({
									formModal: false,
									thanksModal: false,
									chatbotModal: true
								})
							}
						>
							Je pose ma question
						</a>
						<Link
							className="footer__navbar-link focused"
							to="/AboutUs"
						>
							Qui sommes-nous ?
						</Link>
					</nav>
				);
		}
	}

	return (
		<>
			<div className="footer__navbar">
				<div className="footer__navbar-container">
					<div className="footer__navbar-link-container">
						{getNav()}
					</div>
				</div>
			</div>
			<div className="footer__navbar-rs">
				<a className="focused" rel="noreferrer" href="https://www.youtube.com/@ParlonsRegles" target="_blank">
					<FaYoutube className="footer__rs-ico" />
				</a>
				<a
					className="focused"
					rel="noreferrer"
					href="https://www.instagram.com/regleselementaires/"
					target="_blank"
				>
					<FaInstagram className="footer__rs-ico" />
				</a>
				<a
					className="focused"
					rel="noreferrer"
					href="https://www.tiktok.com/@regleselementaires"
					target="_blank"
				>
					<FaTiktok className="footer__rs-ico" />
				</a>
			</div>
		</>
	);
}

export default NavbarFooter;
