import React, { useState } from "react";
import NavbarUserFooter from "./NavbarUserFooter";
import NavbarFooter from "./NavbarFooter";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";
import logo from "../assets/images/logo-parlons-regles.svg";

function Footer() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const modalStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
			zIndex: "999",
		},
		content: {
			width: "90%",
			height: "90%",
			position: "absolute",
			top: "50%",
			left: "50%",
			right: "0",
			bottom: "0",
			transform: "translate(-50%, -50%)",
			background: "#ffffff",
			padding: "75px",
			border: "2px solid black",
			borderRadius: window.innerHeight <= 750 ? "none" : "20px",
			zIndex: 999,
		},
	};
	const titleStyle = {
		marginBottom: "20px",
		marginTop: "10px"
	};
	const subTitleStyle = {
		marginBottom: "10px",
		marginTop: "10px"
	};
	const subSubTitleStyle = {
		marginBottom: "5px",
		marginTop: "5px"
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};
	return (
		<div className="footer">
			<div className="footer__text-container">
				<img className="footer__logo"
					src={logo}
					alt="Parlons règles par Règles Élémentaires"
				/>
				<p className="footer__text">
					Parlons Règles est un programme d’éducation menstruelle pensé et conçu par l’association Règles Élémentaires. Depuis 2015, Règles Élémentaires est l’association française de référence sur les règles.
				</p>
			</div>
			<NavbarFooter />
			<NavbarUserFooter />
			<div className="footer__mention-legal-container">
				<div className="footer__mention-legal-link-container">
					<p className="footer__mention-legal-by-text">
						Une plateforme créée par <a className="footer__mention-legal-re-site" href="https://www.regleselementaires.com/">Règles Élémentaires</a>, association loi 1901
					</p>
					<button
						onClick={() => setModalIsOpen(true)}
						className="footer__mention-legal-button"
					>
						Mentions légales & politique de confidentialité
					</button>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Mentions légales"
				shouldCloseOnOverlayClick={true}
				ariaHideApp={false}
				style={modalStyle}
			>
				<div className="footer_mention-modal-container">
					<IoCloseOutline
						className="notation__close-button"
						onClick={closeModal}
					/>
					<h2 style={titleStyle}>Mentions légales</h2>
					<h3 style={subTitleStyle}>L'éditeur</h3>
					<p>L’édition du Site est assurée par Règles Élémentaires, association loi 1901, dont le siège social est situé au 9 rue de Vaugirard, 75006 Paris, France.</p>
 					<p>Adresse email : contact@regleselementaires.com</p>
					<p>Directrice de publication : Nadège Moreau</p>
					<p>Délégué à la protection des données : Bastien Germain - bastien@regleselementaires.com</p>
					<h3 style={subTitleStyle}>L'hébergeur</h3>
					<p>Ce site est hébergé sur l'offre Cloud Microsoft (Azure). Il est géographiquement localisé dans le centre d’hébergement de Microsoft en "France Centre" :</p>
					<p>Microsoft France</p>
					<p>37 Quai du Président Roosevelt</p>
					<p>92130 ISSY-LES-MOULINEAUX</p>
					<h2 style={titleStyle}>Politique de confidentialité</h2>
					<h3 style={subTitleStyle}>1. Préambule</h3>
					<p>La présente politique de confidentialité décrit comment l’association Règles Elémentaires, via son projet Parlons Règles (ci-après « nous » ou « l’Association ») collecte, utilise, partage et sécurise les données personnelles que les utilisateurs, potentiellement mineurs, fournissent via notre site internet [www.parlonsregles.fr ] (le « Site Internet »).</p>
					<p>Elle prévoit un ensemble de principes et lignes directrices pour la protection des données recueillies par l’Association dans le cadre de ses activités.</p>
					<p>Cette politique est en conformité avec la directive 2002/58/CE « vie privée et communications électroniques » (« ePrivacy »), le Règlement 2016/679 relatif à la protection des données (« RGPD ») et la Loi n°2018/493 du 20 juin 2018, promulguée le 21 juin 2018, modifiant la loi du 6 janvier 1978 (dite « Loi Informatique et Libertés »).</p>
					<p>Elle est susceptible d’être modifiée ou complétée à tout moment par l’Association, notamment en vue de se conformer à toute évolution législative, règlementaire, jurisprudentielle ou technologique.</p>
					<h3 style={subTitleStyle}>2. Protection des données personnelles</h3>
					<h4 style={subSubTitleStyle}>2.1. Désignation d’un responsable du traitement des données</h4>
					<p>Le responsable de la collecte des données est Règles Élémentaires, une association loi 1901, basée à la Cité Audacieuse, 9 rue de Vaugirard, 75006 Paris.</p>
					<p>Il est joignable à l’adresse mail suivante : bastien@regleselementaires.com</p>
					<h4 style={subSubTitleStyle}>2.2. Données personnelles collectée</h4>
					<p>L’Association n’entend collecter qu'un type de données personnelles auprès des utilisateurs, à savoir des adresses e-mail. Plus particulièrement, l’Association ne collectera aucune donnée de santé relative aux utilisateurs.</p>
					<p>À titre d’illustration, l’Association ne collecte ni traite aucune donnée saisie par les utilisateurs sur le module de chatbot figurant sur le Site Internet.</p>
					<h4 style={subSubTitleStyle}>2.3. Interdiction du traitement des données à caractère sensible </h4>
					<p>Conformément à l’article 9 du RGPD, l’Association s’interdit de procéder à la collecte et au traitement de données personnelles qui relèvent un caractère sensible sur le Site Internet.</p>
					<h4 style={subSubTitleStyle}>2.4. Finalités et bases légales du traitement</h4>
					<p>Les adresses e-mails collectées par l'Association le sont dans le cadre du consentement de l'utilisateur à être recontacté par l'Association pour le tenir informé des projets de l'Association. Les données à caractère personnel qui pourraient être collectées le seraient dans le cadre de la visite de l’utilisateur du Site Internet et seraient recueillies auprès de lui, afin de fournir un contenu de Site Internet pertinent et mesurer ou comprendre l'efficacité du Site Internet (sur la base de votre consentement, ou des intérêts légitimes de l’Association à orienter le contenu fourni)</p>
					<h4 style={subSubTitleStyle}>2.5. Durée de conservation des données personnelles</h4>
					<p>L’Association ne conserve les données personnelles de l’utilisateur que pendant la durée nécessaire à la réalisation des finalités pour lesquels il les a collectées, en ce compris afin de satisfaire aux exigences légales, réglementaires, comptables ou autre, ainsi qu'à son intérêt légitime à conserver ces informations personnelles dans ses dossiers.</p>
					<p>Dans certaines circonstances, l’Association pourrait anonymiser les données personnelles des utilisateurs afin qu'elles ne puissent plus leur être associées, auquel cas l’Association pourra utiliser ces informations (notamment à des fins statistiques) sans limitation de durée.</p>
					<p>Lorsque l’Association n’aura plus besoin des informations personnelles aux fins pour lesquelles elles ont été collectées, l’Association les détruira en toute sécurité conformément aux lois et réglementations applicables.</p>
					<h4 style={subSubTitleStyle}>2.6. Mesures de sécurité dans la conservation des données</h4>
					<p>L’Association met en œuvre des mesures de sécurité pour préserver la sécurité des données collectées, pour une durée strictement nécessaire à la bonne gestion des relations de l’Association avec les utilisateurs, tout en respectant les contraintes légales et règlementaires.</p>
					<p>Par ailleurs, en cas d’incident de sécurité affectant des données personnelles (destruction, perte, altération ou divulgation), l’Association s’engagerait à respecter l’obligation de notification des violations de données personnelles, notamment le cas échéant auprès de la CNIL.</p>
					<h4 style={subSubTitleStyle}>2.7. Modalités de transfert des données personnelles</h4>
					<p>L’Association n’entend pas transférer de données à l’étranger. Si cela venait à évoluer, l’Association informerait les utilisateurs et s’assurerait que ce transfert soit effectué vers des pays listés par la Commission Européenne comme protégeant suffisamment les données et/ou encadré par des garanties appropriées et reposant sur des clauses contractuelles standards, conformément aux modèles publiés par la Commission Européenne et/ou ayant obtenus une certification reconnue.</p>
					<h4 style={subSubTitleStyle}>2.8. Droit des utilisateurs</h4>
					<p>L’Association entend rappeler les dispositions de l’article 5 du RGPD applicables dans le cadre de la collecte et du traitement des données :</p>
					<ul>
						<li>les données personnelles de chaque utilisateur sont traitées de manière licite, loyale et transparente;</li>
						<li>les données personnelles sont collectées pour des finalités déterminées, et ne peuvent être traitées ultérieurement d’une manière incompatible avec ces finalités. Le traitement de ces données est limité à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées;</li>
						<li>les données personnelles qui sont inexactes, sont soient effacées ou immédiatement rectifiées;</li>
						<li>les données personnelles sont conservées pendant une durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées; et</li>
						<li>les données personnelles sont traitées de façon à garantir une sécurité appropriée.</li>
					</ul>
					<h4 style={subSubTitleStyle}>2.9. Exercice des droits concernant le traitement des données</h4>
					<p>Conformément aux dispositions du RGPD, les utilisateurs disposent des droits suivants concernant leur données personnelles :</p>
					<ul>
						<li>droit d’accès : communication des données personnelles faisant l’objet d’un traitement par l’Association;</li>
						<li>droit de rectification, compléter ou mettre à jour : mise à jour des données personnelles ou rectification des données personnelles traitées par l’Association;</li>
						<li>droit d’opposition : suppression des données personnelles;</li>
						<li>droit à la limitation de traitement : limitation (c’est-à-dire la suspension) du traitement des données personnelles;</li>
						<li>retirer le consentement : retirer le consentement pour les traitements de données personnelles soumis à consentement;</li>
						<li>droit à la portabilité : récupérer les données personnelles afin d’en disposer ou de transférer les données personnelles à un nouvel organisme.</li>
					</ul>
					<p>Afin d’exercer un ou plusieurs de ces droits, les utilisateurs peuvent adresser une demande par courrier ou courriel à l’adresse susmentionnée à la section 2.1.</p>
					<p>Si l’utilisateur considère que l’Association ne respecte pas ses obligations, il peut adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité compétente en matière de protection des données est la CNIL (https://www.cnil.fr, 3 Pl. de Fontenoy, 75007 Paris).</p>
				</div>
			</Modal>
		</div>
	);
}

export default Footer;
