import { useState, React } from "react";
import Modal from "react-modal";
import { IoCloseOutline } from "react-icons/io5";
import personnage from "../assets/images/chatbot_personnage.svg";
import redLogo from "../assets/images/logo-parlons-regles.svg";

function ChatbotPopup() {
	const [modalState, setModalState] = useState({
        isOpen: false
	});

	const modalStyle = {
		overlay: {
			zIndex: "999",
		},
		content: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			height: "100%",
			position: "absolute",
			top: "0",
			left: "0",
			right: "0",
			bottom: "",
			border: "none",
			background: "#FFF",
			padding: "20px",
			borderRadius: 0,
			Zindex: 999,
		},
	};

	// Gestion des ouvertures/fermetures des modals
	const openModal = () => {
		setModalState({ isOpen: true });
	};
	const closeModal = () => {
		setModalState({ isOpen: false });
	};

	return (
		<div className="chatbotPopup">
			<button
				className="chatbotPopup__button focused"
				onClick={openModal}
			>				
				<img className="chatbotPopup__button-img" src={personnage} alt="ouvrir le chatbot" />				
				<p className="chatbotPopup__button-text">Salut ! Moi c’est Timi, je suis là pour répondre à tes questions.</p>
			</button>
			{modalState.isOpen && (
				<Modal
					isOpen={modalState.isOpen}
					onRequestClose={closeModal}
					contentLabel="Chatbot Parlons Règles"
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					style={modalStyle}
				>
                    <div className="chatbotPopup__header">
                        <img
                            className="chatbotPopup__logo"
                            src={redLogo}
                            alt="Logo Parlons Règles"
                        />
                        <IoCloseOutline
                            className="chatbotPopup__modal-close-button"
                            onClick={closeModal}
                        />
                    </div>
					<iframe className="chatbotPopup__iframe" title="chatbot" src="https://chatbot-parlons-regles.vercel.app/"></iframe>
				</Modal>
			)}
		</div>
	);
}

export default ChatbotPopup;