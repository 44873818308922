import { useEffect, useState } from "react";
import useAxios from "../Hook/useAxios";
import useMobile from "../Hook/UseMobile";
import ReseauxSociaux from "./reseauxSociaux";

function TiktokHome() {
	const tiktok = useAxios("/tiktoks?populate=*");
	const [tiktokParams, setTiktokParams] = useState("");
	const isMobile = useMobile();

	useEffect(() => {
		if (tiktok) {
			setTiktokParams(tiktok.data.data[0].attributes);
		}
	});

	return (
		<div className="tiktok">
			<div className="tiktok__left-container">
				<div className="tiktok__title-container">
					<h2 className="tiktok__title">Parlons règles <br /><strong><i>Sur les réseaux</i></strong></h2>
					<p className="tiktok__subtitle">Rejoins-nous sur nos réseaux sociaux, on y poste d'autres vidéos, tu trouveras forcément un sujet qui t'intéresse !</p>
				</div>
				{!isMobile && <ReseauxSociaux />}
			</div>
			<div className="tiktok__tiktok-container">
				{(tiktokParams && tiktokParams !== "") &&
					<div className="tiktok__img-container">
						<a href={tiktokParams.link} target="_blank" rel="noreferrer">
							<img src={tiktokParams.img.data.attributes.url} alt="aperçu tiktok" />
						</a>
					</div>									
				}
			</div>
			{isMobile && (<ReseauxSociaux />)}
		</div>
	);
}

export default TiktokHome;