import React, { useState } from "react";
import NavbarUser from "./NavbarUser.jsx";
import { Link } from "react-router-dom";
import { useUser } from "../Hook/useUser.jsx";
import redLogo from "../assets/images/logo-parlons-regles.svg";
import HappyCup from "../assets/images/HappyCup.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";

function NavbarHeader() {
	const { user, setModalQuestionIsOpen } = useUser();
	const [modalIsOpen, setModalIsOpen] = useState(false);

	function getNav() {
		switch (user) {
			default:
				return (
					// Liens pour l'utilisateur "Jeunes"
					<nav className="navbar__red-nav">
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Jeunes/ToutSurLesRegles"
						>
							Vidéos sur les règles
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Jeunes/QuestionsUtilisateurs"
						>
							questions les plus posées
						</Link>
						<p
							onClick={() =>
								setModalQuestionIsOpen({
									formModal: false,
									thanksModal: false,
									chatbotModal: true
								})
							}
							className="navbar__link focused"
						>
							{/* link for keyboard navigation */}
							<a href="#" className="focused">
								je pose ma question
							</a>
						</p>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/AboutUs"
						>
							Qui sommes-nous ?
						</Link>
					</nav>
				);
			case "parent":
				return (
					// Liens pour l'utilisateur "Parents"
					<nav className="navbar__blue-nav">
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Parents/AllArticles"
						>
							Le blog des règles
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Parents/QuestionsUtilisateurs"
						>
							Questions les plus courantes
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/AboutUs"
						>
							Qui sommes-nous ?
						</Link>
					</nav>
				);
			case "relai":
				return (
					// Liens pour l'utilisateur "Relais"
					<nav className="navbar__blue-nav">
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/Relais/Ressources"
						>
							Ressources
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="https://www.regleselementaires.com/agir/ecole/#sensibiliser"
							target="_blank" rel="noreferrer"
						>
							Organiser un atelier
						</Link>
						<Link
							onClick={closeModal}
							className="navbar__link focused"
							to="/AboutUs"
						>
							Qui sommes-nous ?
						</Link>
					</nav>
				);
		}
	}

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	return (
		<div className="navbar">
			<NavbarUser />
			<div className="navbar__navbar-container">{getNav()}</div>
			<div className="modal__show-modal">
				<Link
					className="focused"
					to={
						user === "young"
							? "/"
							: user === "parent"
							? "/Parents"
							: "/Relais"
					}
				>
					<img
						className="modal__show-modal-logo"
						src={redLogo}
						alt="Parlons règles par Règles Élémentaires"
					/>
				</Link>
				<RxHamburgerMenu
					onClick={openModal}
					className="modal__burger-ico"
					style={{ color: "#9129FF" }}
				/>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Menu Modal"
				shouldCloseOnOverlayClick={true}
				ariaHideApp={false}
				style={{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.7)",
						zIndex: "999",

					},
					content: {
						width: "100vw",
						height: "100%",
						position: "absolute",
						top: "0",
						left: "0",
						right: "0",
						bottom: "0",
						border: "none",
						background: "white",
						padding: 0,
						borderRadius: 0,
						Zindex: "999",
					},
				}}
			>
				<>
					<div className="modal__header-container">
						<Link
							className="focused"
							to={
								user === "young"
									? "/"
									: user === "parent"
									? "/Parents"
									: "/Relais"
							}
						>
							<img
								className="modal__show-modal-logo focused"
								src={redLogo}
								alt="Parlons règles par Règles Élémentaires"
							/>
						</Link>
						<IoCloseOutline
							className="modal__close-button"
							style={{
								color: "#9129FF",
							}}
							onClick={closeModal}
						/>
					</div>
					<div className="modal__navbar-container-modal">
						<div className="modal__navbar-modal">{getNav()}</div>
					</div>
					<div className="modal__navbar-user-container">
						<img
							className="modal__happy-cup"
							src={HappyCup}
							alt="Un tampon joyeux avec des jambes"
						/>
						<NavbarUser
							closeModal={closeModal}
							bColor={"#FFB800"}
						/>
					</div>
				</>
			</Modal>
		</div>
	);
}

export default NavbarHeader;
