import AtelierOrganise from "../../components/AtelierOrganise";
import HeaderRelai from "../../components/HeaderRelai";
import PartnersFooter from "../../components/PartnersFooter";
import WhiteBookRelai from "../../components/WhiteBookRelai";
import SwapArticle from "../../components/SwapArticle";

function HomeRelay() {
    return (
      <div className="HomeRelay">
          <HeaderRelai />
          <WhiteBookRelai />
          <SwapArticle locate="Relais" />
          <AtelierOrganise />
          <PartnersFooter />
      </div>
    );
  }

export default HomeRelay;