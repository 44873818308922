import React, { useEffect } from 'react';
import './styles/App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavbarHeader from './components/navigation';
import ScrollToTop from "./components/ScrollToTop";
// Pages Jeunes
import HomeYoung from './Pages/Jeunes/HomeYoung';
import AllOnPeriods from './Pages/Jeunes/AllOnPeriods';
import QuestionsUsers from './Pages/Jeunes/QuestionsUsers';
import Result from './Pages/Jeunes/Result';
//Pages Parents
import HomeAdult from './Pages/Parents/HomeAdults';
import AllArticles from './Pages/Parents/AllArticles';
import QuestionUserParent from "./Pages/Parents/QuestionUserParent";
import SpecificArticles from './Pages/Parents/SpecificArticle';
//Page Relais
import HomeRelay from './Pages/Relay/HomeRelay';
//Page transverse
import PartnersPage from './Pages/PartnersPage';
import AboutUsPage from './Pages/AboutUsPage';

//Composants
import Footer from './components/Footer';
import { useUser } from './Hook/useUser';

function App() {
	const {updateUser} = useUser();

	useEffect(() => {
		const handleUrlChange = () => {
			const location = window.location.href;
			if (location.includes('Parents')) {
			  updateUser('parent');
			} else if (location.includes('/Relais')) {
			  updateUser('relai');
			} else {
			  updateUser('young');
			}
		  };
			// Écoutez les changements d'URL lors du chargement de la page à partir de l'historique du navigateur
			window.addEventListener('popstate', handleUrlChange);

			// Nettoyez l'écouteur d'événements lors du démontage du composant
			return () => {
			window.removeEventListener('popstate', handleUrlChange);
			};
	}, [updateUser])

	return (
		<div className="App">
			<Router>
				<ScrollToTop />
				<NavbarHeader />
				<Routes>
					{/*Routes Jeunes*/}
					<Route path="/" element={<HomeYoung />} />
					<Route path="/Jeunes/ToutSurLesRegles" element={<AllOnPeriods />} />
					<Route path="/Jeunes/QuestionsUtilisateurs" element={<QuestionsUsers parent={false} />} />
					<Route path="/Jeunes/Resultats" element={<Result />} />
					{/*Routes Parents*/}
					<Route path="/Parents" element={<HomeAdult />} />
					<Route path="/Parents/QuestionsUtilisateurs" element={<QuestionUserParent />} />
					<Route path="/Parents/AllArticles" element={<AllArticles locate="Parents"/>} />
					<Route path="/article/:title/:id" element={<SpecificArticles />} />
					{/*Routes Relais*/}
					<Route path="/Relais" element={<HomeRelay />} />
					<Route path="/Relais/Ressources" element={<AllArticles locate="Relais"/>} />

					{/*Routes Communes*/}
					<Route path="/Partenaires" element={<PartnersPage />} />
					<Route path="/AboutUs" element={<AboutUsPage />} />
				</Routes>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
